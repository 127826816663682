<template lang="pug">

  .assessments()
    
    .row.mt-3(v-if="selected_survey")
      .col-2
      .col-8
        .center-block
          div(style="max-width: 800px")
            h1.mt-5 Welcome
            p {{survey_template.description}}
            b-card(v-if="selected_survey.relations.topic_id")
              p() {{selected_survey.relations.topic_id.description}}
             
            Survey(:survey_template="survey_template", @saved="getData()" v-model="selected_survey", :link_token="$route.params.link_token" :editable="true", :edit_mode="true").mt-5
        

</template>

<script>

import Vue from 'vue';
import Config from '@/config'
import Survey from '@/components/Survey.vue'
import User from '@/components/User.vue'
import { mapFields } from 'vuex-map-fields';

export default {
  
  name: 'Assessement',
  
  data: function () {
      return {
          survey_template: null,
          selected_survey: null,
          surveys: null,
          has_own_assessment: false
      }
  },

  mounted: function() {
   
    this.host = Config.config.base_host;
    this.getData();
  
  },

  watch: {
    
  },

  computed: {
       
        ...mapFields([
         

        ]),
  },

  
  methods: {

      create_new() {
        this.selected_survey = {"relations": {"owner_id": this.$store.state.user}, "custom_data": {}, "owner_id": this.$store.state.user.id, "survey_type": "use_case_expert_assessement", "topic_id": this.$route.params.topic_id};
      },
    
      getData() {
        
        this.$store.dispatch("start_loading");


        Vue.http.get(`${Config.config.base_host}/surveys/by_token/${this.$route.params.link_token}`).then(res => {
            this.selected_survey = res.body;
            this.survey_template = res.body.relations.survey_template_id;
            this.$store.dispatch("stop_loading");
        });

        
      }
     
  },
  components: {
      Survey,
      User
  }
}

</script>
<style lang="scss" scoped>
    a {
        text-decoration: none !important;
    }
</style>